<template>
	<div class="">
		<div class="bg-white radius-20 prl-20 pt-20 ">
			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">설정</h3>
			</div>

			<div>
				<ul>
					<template
						v-for="(menu, m_index) in items_menu"
					>
						<li
							v-if="menu.permission"
							:key="'menu_' + m_index"
							class="under-line-not-last ptb-20 cursor-pointer"
							:class="{on: menu.to.name == $route.name}"
							@click="toSetting(menu)"
						>
							<div class="">{{  menu.title }}</div>
						</li>
					</template>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CartelSetting'
		, props: ['user']
		, data: function(){
			return {
				item_permission: {}
			}
		}
		, computed: {

			items_menu: function(){
				return [
					{ name: 'member', title: '카르텔 설정', to: { name: 'mafia074', params: {idx: this.$route.params.idx}}, permission: this.is_boss}
					, { name: 'member', title: '운영자 관리', to: { name: 'mafia084', params: {idx: this.$route.params.idx}}, permission: this.is_underboss_manage}
					, { name: 'member', title: '게시판 관리', to: { name: 'mafia122', params: {idx: this.$route.params.idx}}, permission: this.is_board_manage}
					, { name: 'member', title: '멤버등급 관리', to: { name: 'mafia0841', params: {idx: this.$route.params.idx}}, permission: this.is_member_grade_manage}
					, { name: 'member', title: '멤버 관리', to: { name: 'mafia091', params: {idx: this.$route.params.idx}}, permission: this.is_member_manage}
					, { name: 'member', title: '카르텔 알림', to: { name: 'mafia076', params: {idx: this.$route.params.idx}}, permission: true}
					, { name: 'member', title: '멤버 보기', to: { name: 'mafia082', params: {idx: this.$route.params.idx}}, permission: true}
					, { name: 'member', title: '카르텔 통계', to: { name: 'mafia0911', params: {idx: this.$route.params.idx}}, permission: true}
					, { name: 'member', title: '카르텔 탈퇴', to: { name: 'mafia083', params: {idx: this.$route.params.idx}}, permission: !this.is_boss}
					, { name: 'member', title: '카르텔 폐쇄', to: { name: 'mafia0831', params: {idx: this.$route.params.idx}}, permission: this.is_boss}
				]
			}
			, is_boss: function(){
				let t = false

				if(this.item_permission.cartl_member_div_code == 'CA02500001'){
					t = true
				}
				return t
			}
			, is_underboss_manage: function(){
				let t = false
				if(this.item_permission.cartl_admin_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_board_manage: function(){
				let t = false
				if(this.item_permission.cartl_board_creation_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_member_manage: function(){
				let t = false
				if(this.item_permission.cartl_member_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_member_grade_manage: function(){
				let t = false
				if(this.item_permission.cartl_member_grade_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
		}
		, methods: {
			toSetting: function(item){
				this.$bus.$emit('to', item.to)
			}

			, getPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.item_permission = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$emit('click', this.items_menu[0])
			console.log('user', this.user)
			this.getPermission()
		}
	}
</script>