<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<CartelSetting
			:user="user"

			class="flex-side mr-30"
		></CartelSetting>

		<div class="flex-1 bg-white pa-20 radius-20">

			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">운영자 관리</h3>
			</div>
			<!-- 커버 -->
			<div class="mt-30 text-center">
			<!-- 멤버검색 상단 -->
				<div class="box radius-20 pa-10-20 flex-row items-center">
					<input
						v-model="item_search.search_value"
						type="text" placeholder="멤버 검색"
						maxlength="20"
						class="flex-1"
						@keyup.enter="getManager"
					>
					<v-icon
						v-if="item_search.search_value"
						@click="item_search.search_value = ''; getManager()"
						class="mr-10"
					>mdi-close-circle</v-icon>
					<v-icon
						@click="getManager"
						type="submit" name="click"
					>mdi-magnify</v-icon>
				</div>

				<div class="mt-30 text-left">

					<h4 class="">현재 운영자</h4>

					<ul
						v-if="items_under_boss.length > 0"
						class="mt-30"
					>
						<li
							v-for="(item_under_boss, index) in items_under_boss"
							:key="'item_' + index"
							class="mb-10"
						>
							<div class="flex-row items-center">
								<div class="square-64 overflow-hidden radius-100 mr-10">
									<img
										:src="$request.upload_url(item_under_boss.admin_profle_nft_card_img_url)"
										@error="$bus.$emit('onErrorProfileImage', $event)"
										class="width-100 "
									>
								</div>
								<div class="flex-1">
									<strong>{{ item_under_boss.admin_nickname }}</strong>
									<p class="color-gray">
										{{  item_under_boss.admin_nickname }}
									</p>
								</div>
								<div>
									<button
										@click="onPermission(item_under_boss)"
										class="btn-inline btn-primary-outline radius-20 mr-10"
									>권한</button>
									<button
										@click="onConfirm(item_under_boss, 'fire')"
										class="btn-inline btn-primary-outline radius-20"
									>해임</button>
								</div>
							</div>
						</li>
					</ul>

					<div
						v-else
						class="mt-30"
					>
						<div class="flex-row items-center">
							<div class="square-64 overflow-hidden radius-100 mr-10">
								<img
									:src="require('@/assets/image/@nophoto.png')" alt="운영자 임명 필요"
									class="width-100"
								>
							</div>
							<div class="flex-1">
								<strong
									class="underboss_name"
								>운영자 임명이 필요합니다</strong>
							</div>
							<div>
								<button
									class="btn-inline btn-primary-outline radius-20 mr-10"
									disabled="true"
								>권한</button>
								<button
									class="btn-inline btn-primary-outline radius-20"
									disabled="true"
								>해임</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<hr class="under-line mt-30 ">

			<div class="mt-30">
				<h4>운영자 조건에 충족된 멤버</h4>
				<ul
					v-if="items_member.length > 0"
					class="mt-30"
				>
					<li
						v-for="(item, index) in items_member"
						:key="'item_' + index"
						class="mb-20"
					>
						<div class="flex-row items-center">
							<div class="square-64 overflow-hidden radius-100 mr-10">
								<img
									:src="$request.upload_url(item.profle_nft_card_img_url)"
									@error="$bus.$emit('onErrorProfileImage', $event)"
									class="width-100"
								>
							</div>
							<div class="flex-1">
								<strong>{{  item.nickname }}</strong>
								<p>
									{{  item.cartl_member_grade_name }}
								</p>
							</div>
							<div>
								<button
									@click="onConfirm(item, 'confirm')"
									class="btn-inline btn-primary-outline radius-20"
									:disabled="item.is_boss"
								>임명</button>
							</div>
						</div>
					</li>
				</ul>
				<div
					v-else
					class="none mt-30"
				>{{ none_text }}</div>
			</div>
		</div>

		<PopupConfirm
			v-if="is_confirm"
			@click="postConfirm"
			@cancel="cancelConfirm"
			class="text-center"
		>
			<template v-slot:title>운영자 {{ title_text }}</template>
			<template
				v-slot:main-txt
			>아래 멤버를 운영자로 {{ title_text }}하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>{{ item_user.nickname }}</template>

		</PopupConfirm>

		<mafia089
			v-if="is_089"
			:user="user"
			:member="item_user"
			:cartel="item_cartel"

			@click="cancelPermission"
			@cancel="cancelPermission"

		></mafia089>
	</div>
</template>

<script>

	import mafia089 from "@/view/Cartel/mafia089"
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	import CartelSetting from "@/view/Cartel/CartelSetting";
	export default {

		name: 'mafia084'
		, components: {CartelSetting, PopupConfirm, mafia089}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '운영자 관리'
					, title: '운영자 관리'
					, not_header: true
					, not_footer: true
					, type: 'cartel_sub'
					, bg_contents: 'bg-gray01'
				}
				, is_scroll: true
				, scrollTop: 0
				, item_search: {
					search_value: ''
					, page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum
				}
				, item_cartel: {}
				, items_member: []
				, is_search: false
				, is_confirm: false
				, item_user: {
					permission: {
						undrbos_mangement: ''
					}
				}
				, items_under_boss: []
				, confirm_type: null
				, is_089: false
				, items_board_config: []
			}
		}
		, computed: {
			none_text: function(){
				let t = '검색어를 입력하세요'

				if(this.is_search && this.items_member.length == 0){
					t = '검색 결과가 없습니다.'
				}

				return t
			}
			, title_text: function(){
				let t = '임명'
				if(this.confirm_type == 'fire'){
					t = '해임'
				}

				return t
			}

		}
		, methods:{

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onPermission: function(user){
				this.item_user = user
				this.is_089 = true
			}
			, cancelPermission: function(){
				this.is_089 = false
				this.item_user = null
			}
			, postCartelFire: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_manager_fire
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, admin_member_number: this.item_user.admin_member_number
						}
						, type: true
					})
					if(result.success){
						await this.getManager()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_confirm = false
				}
			}
			, getManager: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_manager
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, srchtext: this.item_search.search_value
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})
					if(result.success){
						this.items_under_boss = result.data.current_admin_list ? result.data.current_admin_list : []
						this.items_member = result.data.operating_targeter_list

						console.log(this.items_member)

					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_search = true
				}
			}
			, postConfirm: function(){
				switch (this.confirm_type){
					case 'confirm':
						this.postAppoint()
						break
					case 'fire':
						this.postCartelFire()
						break
					default:
						this.$bus.$emit('notify', { type: 'error', message: '잘못된 요청입니다'})
						break
				}
			}
			, postAppoint: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_manager_appoint
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, admin_member_number: this.item_user.member_number
						}
						, type: true
					})

					if(result.success){
						await this.getManager()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_confirm = false
				}
			}
			, onConfirm: function(user, type){
				this.item_user = user
				this.confirm_type = type
				this.is_confirm = true
			}
			, postCartelPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_permission
						, data: {
							cartel_id: this.$route.params.idx
							, user: this.item_user
						}
						, name: 'postCartelPermission'
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_089 = false
				}
			}
			, cancelConfirm: function(){
				this.is_confirm = false;
			}
		}
		, async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getData()
			await this.getManager()

			let self = this
			document.body.addEventListener('scroll', function(){
				self.scrollTop = document.body.scrollTop
				let max = 162

				if(Number(document.body.scrollTop) >= max){
					self.is_scroll = true
				}else{
					self.is_scroll = true
				}
			});
		}
	}

</script>